import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  },
  ul.technologies-list {
    display: list-item;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  },
  `;

const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const languages = [
    'Major: C#, Java, Python, JavaScript, SQL, TypeScript',
    'Minor: Kotlin, Swift, Objective-C, C++, PHP',
  ];

  const dotNetCSharp = [
    'C# language features and best practices',
    '.NET Framework, .NET Core, .NET 6 and newer versions',
    'Autofac, MEF',
    'Entity Framework (EF) and Entity Framework Core (EF Core), Dapper, LINQ, oData, Telus, Elsa Workflow',
    'NUnit, xUnit.net, MSTest, Moq, NSubstitute, SpecFlow',
    '.NET MAUI, Xamarin Forms, PRISM',
    'JSON, XML, Hangfire',
    'ASP.NET Identity, OAuth 2.0, OIDC (OpenID Connect), JWT (JSON Web Tokens)',
  ];

  const java = [
    'Spring (Core, MVC, Boot, Data, Security, Cloud)',
    'Hibernate or JPA (Java Persistence API), JDBC (Java Database Connectivity)',
    'Tests (JUnit, Mockito)',
    'JSON, XML processing libraries (e.g., Jackson, JAXB)',
    'Maven, Apache Tomcat',
  ];

  const python = ['Flask, Django, NumPy, SciPy', 'Behave, Pytest, Unittest'];

  const nodeJs = ['Node.js, Express.js, Nest.js', 'Mongoose, Sequelize, TypeORM'];

  const frontend = [
    'JavaScript (ES6+), TypeScript',
    'Angular, NgRx, Redux',
    'React.js, Gatsby, Next.js, RxJs',
    'Jest, Cucumber, WebdriverIO, Jasmine',
    'HTML, CSS, Sass, SCSS, Playwright, API Testing, Selenium, Gherkin, Applitools',
  ];

  const desktop = ['WPF, WinForms, UWP, Avalonia, Electron'];

  const mobile = [
    'MAUI, Xamarin, Xamarin.Forms',
    'Swift, Objective-C, iOS SDK, UIKit, SwiftUI, Xcode',
    'Java, Kotlin, Android SDK, Android Studio',
    'React Native',
  ];

  const sqlDatabases = ['MySQL, PostgreSQL, MS SQL Server, SQLite'];

  const noSqlDatabases = ['MongoDB, Redis, ElasticSearch, Cassandra, Neo4j, CouchDB, DynamoDB'];
  const versionControl = ['Git, GitHub, GitLab, Bitbucket, Azure DevOps, TFS, SVN'];
  const containerizationAndOrchestration = [
    'Docker, Kubernetes, Helm, Azure Kubernetes Service (AKS)',
  ];

  const cloudPlatforms = [
    'AWS (e.g., EC2, Lambda)',
    'Microsoft Azure, Azure services (Service Fabric, Azure Functions, Azure App Service, Azure SQL Database, Azure DevOps',
  ];

  const monitoringAndLogging = [
    'ELK Stack (Elasticsearch, Logstash, Kibana)',
    'Grafana',
    'Prometheus',
    'Splunk',
    'Application Insights',
    'Azure Monitor',
    'Serilog or NLog',
    'GrayLog',
  ];

  const messageBrokersAndQueues = [
    'RabbitMQ, Kafka, Azure Service Bus, Azure Event Grid, Azure Event Hubs',
  ];

  const cicd = ['Azure Pipelines', 'Jenkins with .NET plugins', 'TeamCity', 'Octopus'];
  const software = [
    'macOS, Windows, Linux',
    'JetBrains (Rider, IntelliJ IDEA, PyCharm, WebStorm, DataGrip)',
    'Microsoft (Visual Studio, VS Code)',
    'Xcode',
    'Web Engines (Apache, Nginx, IIS)',
    'FireBase',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              A highly self-motivated Software Engineer with more than eight years of hands-on
              experience and strong mathematical background. Experience includes meeting tight
              deadlines, delivering software to 40,000+ users, and building performance-critical
              systems ground-up (fullstack development). Primary language C#, many experiences with
              Java and Python. Has theoretical and practical knowledge of programming design
              templates, algorithms, data structures, and flexible methods of software development.
            </p>

            {/*<p>*/}
            {/*  Fast-forward to today, and I’ve had the privilege of working at{' '}*/}
            {/*  <a href="https://us.mullenlowe.com/">an advertising agency</a>,{' '}*/}
            {/*  <a href="https://starry.com/">a start-up</a>,{' '}*/}
            {/*  <a href="https://www.apple.com/">a huge corporation</a>, and{' '}*/}
            {/*  <a href="https://scout.camd.northeastern.edu/">a student-led design studio</a>. My*/}
            {/*  main focus these days is building accessible, inclusive products and digital*/}
            {/*  experiences at <a href="https://upstatement.com/">Upstatement</a> for a variety of*/}
            {/*  clients.*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*  I also recently{' '}*/}
            {/*  <a href="https://www.newline.co/courses/build-a-spotify-connected-app">*/}
            {/*    launched a course*/}
            {/*  </a>{' '}*/}
            {/*  that covers everything you need to build a web app with the Spotify API using Node*/}
            {/*  &amp; React.*/}
            {/*</p>*/}

            <p>LANGUAGES:</p>
            <ul className="technologies-list">
              {languages && languages.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>FRAMEWORKS & LIBRARIES:</p>
            <br />
            <p>C#/.Net:</p>
            <ul className="technologies-list">
              {dotNetCSharp && dotNetCSharp.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Java:</p>
            <ul className="technologies-list">
              {java && java.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Python:</p>
            <ul className="technologies-list">
              {python && python.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Node.Js</p>
            <ul className="technologies-list">
              {nodeJs && nodeJs.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Frontend:</p>
            <ul className="technologies-list">
              {frontend && frontend.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Desktop:</p>
            <ul className="technologies-list">
              {desktop && desktop.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Mobile:</p>
            <ul className="technologies-list">
              {mobile && mobile.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>SQL databases:</p>
            <ul className="technologies-list">
              {sqlDatabases && sqlDatabases.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>NoSQL databases:</p>
            <ul className="technologies-list">
              {noSqlDatabases && noSqlDatabases.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Version Control:</p>
            <ul className="technologies-list">
              {versionControl && versionControl.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Containerization & Orchestration:</p>
            <ul className="technologies-list">
              {containerizationAndOrchestration &&
                containerizationAndOrchestration.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Cloud Platforms:</p>
            <ul className="technologies-list">
              {cloudPlatforms && cloudPlatforms.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Monitoring & Logging:</p>
            <ul className="technologies-list">
              {monitoringAndLogging &&
                monitoringAndLogging.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Message Brokers & Queues:</p>
            <ul className="technologies-list">
              {messageBrokersAndQueues &&
                messageBrokersAndQueues.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Continuous Integration/Continuous Deployment (CI/CD):</p>
            <ul className="technologies-list">
              {cicd && cicd.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
            <p>Software:</p>
            <ul className="technologies-list">
              {software && software.map((value, i) => <li key={i}>{value}</li>)}
            </ul>
            <br />
          </div>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpeg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
