import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';

const StyledSubscribeFormSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: center;
  height: 30vh;

  .message {
    color: var(--green);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    margin-top: 1.5vh;

    @media (max-width: 430px) {
      margin-top: 70px;
    }
  }

  .subscribe-form {
    margin-top: 1vh;
  }

  .form-column {
    display: flex;
    flex-direction: column;
    height: 10vh;

    @media (min-width: 320px) and (max-width: 375px) {
      height: 25vh;
    }

    @media (min-width: 768px) and (max-width: 1080px) {
      height: 10px;
    }
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 7vh;
  }

  .email-input {
    ${({ theme }) => theme.mixins.bigButton};
    margin-right: 5px;
    margin-top: 20px;
    width: 38.3vh;
    height: 3vh;
  }

  .submit-button {
    ${({ theme }) => theme.mixins.bigButton};
    margin: 3px;
    line-height: 0;

    @media (min-width: 320px), (max-width: 375px) {
      margin: 1vh 0 0 0;
    }
  }

  .description-message {
    margin-top: 10px;
  }
`;

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleInputChange = event => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (email === '') {
      setMessage('Please enter your email address.');
      setTimeout(() => {
        setMessage('');
      }, 1000);
      return;
    }
    const result = await addToMailchimp(email);
    setMessage(result.msg);
    setTimeout(() => {
      setMessage('');
    }, 1000);
  };

  return (
    <StyledSubscribeFormSection>
      <form
        name="subscribeForm"
        method="POST"
        id="subscribe-form"
        className="subscribe-form"
        onSubmit={handleSubmit}
      >
        <span className="description-message">Join subscription list to improve your skills.</span>
        <div className="form-column">
          <div className="form-row">
            <label>
              <input
                className="email-input"
                type="email"
                name="email"
                placeholder="Enter Email Address..."
                value={email}
                onChange={handleInputChange}
              />
              <button className="submit-button" type="submit">
                Subscribe
              </button>
            </label>
          </div>
          <div className="message" dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </form>
    </StyledSubscribeFormSection>
  );
};

export default SubscribeForm;
